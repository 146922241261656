<template>
    <q-card style="width: 850px; max-width: 80vw;">
        <q-card-section>
            <q-toolbar>
                <q-toolbar-title>
                    Venta
                </q-toolbar-title>
                <q-btn v-if="item._id == ''" dense round color="green" icon="mdi-content-save" class="q-mr-sm"
                    @click="guardar()">
                    <q-tooltip>
                        Guardar
                    </q-tooltip>
                </q-btn>

                <q-btn v-else round color="orange" dense icon="mdi-content-save" class="q-mr-sm" @click="actualizar()">
                    <q-tooltip>
                        Editar
                    </q-tooltip>
                </q-btn>
                <q-btn round color="red" icon="mdi-close" dense @click="cierraVentana()">
                    <q-tooltip>
                        Cerrar
                    </q-tooltip>
                </q-btn>
            </q-toolbar>
            <q-separator class="full-width" color="primary" inset size="4px" />
        </q-card-section>

        <q-card-section class="scroll">
            <div class="row q-col-gutter-sm">
                <div class="col-12 col-md-8">
                    <q-input v-model="item.idVenta" dense filled label="ID Venta" />
                </div>

                <div class="col-12 col-md-4">
                    <q-input v-model="fecha" filled label="Fecha" name="event" outlined dense>
                        <template v-slot:before>
                            <q-icon name="event" color="primary" />
                        </template>
                        <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                            <q-date v-model="item.fecha">
                                <div class="row items-center justify-end">
                                    <q-btn v-close-popup label="Ok" color="primary" flat />
                                </div>
                            </q-date>
                        </q-popup-proxy>
                    </q-input>
                </div>
                <div class="col-12 col-md-12">
                    <q-input v-model="item.descripcion" dense filled label="Descripción" />
                </div>
                <div class="col-12 col-md-12">
                    <q-input v-model="item.promotor" dense filled label="Nombre del promotor" />
                </div>
                <div class="col-12 col-md-12">
                    <q-input v-model="item.responsable" dense filled label="Responsable de seguimiento" />
                </div>
                <div class="col-12 col-md-12">
                    <q-select v-model="item.cliente" emit-value map-options @filter="filtroClientes" option-label="cliente"
                        :options="itemsfiltroclientes" use-input input-debounce="0" dense filled label="Cliente"
                        virtual-scroll-slice-size="1" />
                </div>
                <div class="col-12 col-md-12">
                    <q-field dense filled label="Presupuesto" v-model="item.presupuesto">
                        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                            <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                v-bind="moneyFormat" v-show="floatingLabel" />
                        </template>
                    </q-field>
                </div>
                <!-- <div class="col-12 col-md-12">
                    <q-field dense filled label="Presupuesto" v-model="item.presupuesto">
                        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                            <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                v-bind="moneyFormat" v-show="floatingLabel" />
                        </template>
                    </q-field>
                </div> -->
            </div>
        </q-card-section>
    </q-card>
</template>
<script>
import moment from 'moment';
import axios from 'axios'
import { QSpinnerCube } from 'quasar'
import { Money } from 'v-money'

export default {
    components: {
        Money
    },
    data() {
        return {
            moneyFormat: {
                decimal: '.',
                thousands: ',',
                precision: 2,
                masked: false
            },
            file: null,
            itemsfiltroclientes: null
        }
    },
    computed: {
        item() {
            return this.$store.state.ventaStore;
        },
        token() {
            return this.$store.state.usuario;
        },
        fecha() {
            moment.locale('es-mx');
            return this.item.fecha ? moment.utc(this.item.fecha).format('DD/MMMM/yyyy') : ''
        },
        itemsClientes() {
            return this.$store.state.listaClientesStore;

        }
    },
    watch: {
    },
    created() {
    },
    methods: {
        cierraVentana() {
            this.$emit('cierraVentana')
        },
        async guardar() {
            this.$store.state.ventaStore.fechaCreacion = moment().format('YYYY/MM/DD');
            this.$store.state.ventaStore.usuarioCreador = this.token.username
            this.$store.state.ventaStore.fecha = moment(this.item.fecha).format('YYYY/MM/DD')

            console.log(this.item)
            if (this.item.idVenta == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el ID de la venta' })
                return;
            }

            if (this.item.cliente == null) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Elija el cliente de la venta' })
                return;
            }

            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Guardando venta. Espere...', messageColor: 'white' })

            try {
                let response = await axios.post('ControlVentas/PostVenta/', this.item)
                console.log(response)

                this.item._id = response.data
                this.$store.state.listaVentasStore.push(this.item)
                this.cierraVentana();

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', message: `La venta ha sido guardada exitosamente.` })
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error al guardar, verifique su informacion e intentelo de nuevo.', color: 'red' })
            }
        },

        async actualizar() {
            this.$store.state.ventaStore.fechaCreacion = moment().format('YYYY/MM/DD');
            this.$store.state.ventaStore.usuarioCreador = this.token.username
            this.$store.state.ventaStore.fecha = moment(this.item.fecha).format('YYYY/MM/DD')
            console.log(this.item)
            if (this.item.idVenta == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el ID de la venta' })
                return;
            }

            if (this.item.cliente == null) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Elija el cliente de la venta' })
                return;
            }

            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Actualizando venta. Espere...', messageColor: 'white' })

            try {
                let response = await axios.put('ControlVentas/PutVenta/', this.item)
                console.log(response)

                let indice = this.$store.state.listaVentasStore.findIndex(x => x._id === this.item._id);
                Object.assign(this.$store.state.listaVentasStore[indice], this.item)

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', message: `La venta ha sido actualizado exitosamente.` })
                this.cierraVentana();
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error al actualizar, verifique su información e intentelo de nuevo.', color: 'red' })
            }
        },



        filtroClientes(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroclientes = this.itemsClientes.filter(v => v.cliente.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroclientes.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
    }
}
</script>