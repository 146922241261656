<template >
  <q-layout view="hHh lpR fFf">

    <q-drawer v-model="drawerPerfil" behavior="mobile" side="right" bordered>
      <drawerPerfil></drawerPerfil>
    </q-drawer>

    <q-header elevated v-if="logueado">
      <q-toolbar class="bg-dark">
        <q-btn flat dense round icon="mdi-home" aria-label="Menu" @click="irInicio" />
        <q-toolbar-title>
          <div class="text-h6 text-weight-bolder">CONTROL DE VENTAS</div>
        </q-toolbar-title>
        <div class="text-h6 q-mr-lg">{{ token.username }}</div>
        <q-btn flat round dense icon="mdi-account" @click="drawerPerfil = !drawerPerfil" />
      </q-toolbar>
    </q-header>
    <q-page-container>
      <router-view />
    </q-page-container>
    <!-- <q-footer v-if="logueado" style="height: 30px;" bordered class="bg-dark text-white">
      <q-toolbar>
        <q-toolbar-title>
        </q-toolbar-title>
      </q-toolbar>
    </q-footer> -->
  </q-layout>
</template>

<script>
import axios from 'axios'
import { colors } from 'quasar'
import drawerPerfil from './components/DrawerPerfil/DrawerPerfil.vue'

export default {
  name: 'LayoutDefault',

  components: {
    drawerPerfil
  },

  data() {
    return {
      leftDrawerOpen: false,
      drawerPerfil: false,
      isDarkMode: false,
      leftDrawerOpen: false,
      drawer: false,
      miniState: true,
    }
  },
  created() {
    this.$store.dispatch("autoLogin");
  },
  computed: {
    token() {
      return this.$store.state.usuario;
    },
    logueado() {
      return this.$store.state.usuario;
    },
  },

  methods: {
    irInicio() {
      this.$router.push({ name: 'Home' })
    },
  }
}
</script>


