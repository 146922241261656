<template>
  <q-page class=" q-pa-lg ">

    <!-- DIALOGO PARA GENERAR REPORTE -->
    <q-dialog v-model="prompt" persistent>
      <q-card style="min-width: 350px">
        <q-card-section>
          <div class="text-h6">Reporte</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-select full-width class="q-mb-sm" filled dense v-model="filtroSelectReporte" :options="itempsTipoReport"
            label="Tipo de reporte" @input="ChangeA()" />

          <q-select v-if="filtroSelectReporte != 'Todos'" class="q-mt-sm" filled dense v-model="filtroPR"
            :options="listaPR" option-label="label" label="De" />
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn flat label="Cancelar" v-close-popup />
          <q-btn flat label="Aceptar" @click="ExportarExcel" />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- DIALOGO DE CONFIRMACION -->
    <q-dialog v-model="dialogConfirm" persistent>
      <q-card>
        <q-card-section class="row items-center">
          <q-avatar icon="mdi-delete-alert" color="primary" text-color="white" />
          <span class="text-h5 q-ml-lg">Estás seguro de eliminar la venta {{ this.venta }}.?</span>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="Aceptar" color="primary" @click="PutEstadoVenta()" />
          <q-btn flat label="Cerrar" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- DIALOGO DE CANCELACION -->
    <q-dialog v-model="dialogConfirmCancelacion" persistent>
      <q-card>
        <q-card-section class="row items-center">
          <q-avatar icon="mdi-delete-alert" color="primary" text-color="white" />
          <span class="text-h5 q-ml-lg">Estás seguro de cancelar la venta {{ this.venta }}.?</span>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="Aceptar" color="primary" @click="putEstatus()" />
          <q-btn flat label="Cerrar" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- DIALOGO PARA CREAR UN NUEVO -->
    <q-dialog v-model="dialogNuevo" persistent transition-show="scale" transition-hide="scale">
      <Venta @cierraVentana="dialogNuevo = false"></Venta>
    </q-dialog>

    <!-- AGREGAR HISTORIAL -->
    <q-dialog v-model="dialogHistorial" persistent>
      <q-card style="width: 1300px; max-width: 80vw;">
        <q-card-section>
          <q-toolbar>
            <q-toolbar-title>
              Historial - {{ this.venta }}
            </q-toolbar-title>
            <q-btn dense round color="green" icon="mdi-content-save" class="q-mr-sm" @click="actualizar()">
              <q-tooltip>
                Guardar
              </q-tooltip>
            </q-btn>
            <q-btn round color="red" icon="mdi-close" dense v-close-popup>
              <q-tooltip>
                Cerrar
              </q-tooltip>
            </q-btn>
          </q-toolbar>
          <q-separator class="full-width" color="primary" inset size="4px" />
        </q-card-section>
        <q-card-section class="scroll">
          <div class="row q-col-gutter-sm">
            <div v-if="token.group == 'admin'" class="col-12 col-md-3">
              <div class="row q-col-gutter-sm">
                <div class="col-12 col-md-12">
                  <q-input v-model="fehaMovimiento" filled label="Fecha Inicial" name="event" outlined dense>
                    <template v-slot:before>
                      <q-icon name="event" color="primary" />
                    </template>
                    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                      <q-date v-model="historial.fecha">
                        <div class="row items-center justify-end">
                          <q-btn v-close-popup label="Ok" color="primary" flat />
                        </div>
                      </q-date>
                    </q-popup-proxy>
                  </q-input>
                </div>
                <div class="col-12 col-md-12">
                  <q-input v-model="historial.descripcion" dense filled label="Descripción" />
                </div>
                <div class="col-12 col-md-12">
                  <q-file label="Archivo" v-model="file" dense @input="upload" filled accept=".pdf">
                    <template v-slot:prepend>
                      <q-icon name="attach_file" />
                    </template>
                  </q-file>
                </div>
                <div class="col-12 col-md-12">
                  <q-input v-model="historial.notas" label="Notas" filled type="textarea" />
                </div>
                <div class="col-12 col-md-12">
                  <q-btn color="primary" class="full-width " @click="agregar()" label="Agregar" />
                </div>
              </div>
            </div>
            <div class="col-12 col-md-9">
              <div class="row ">
                <div class="col-12 col-md-12">
                  <q-table class="shadow-0" :data="item.historial" :columns="columnsHistorial" row-key="descripcion"
                    :filter="filter">
                    <template v-slot:body="props">
                      <q-tr :props="props">
                        <!-- <q-td auto-width key="acciones">
                          <q-btn size="md" color="red" rounded flat dense icon="mdi-delete">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                              content-style="font-size: 14px">Eliminar</q-tooltip>
                          </q-btn>
                        </q-td> -->
                        <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                        <q-td key="fecha" :props="props">{{ FormatDate(props.row.fecha) }}</q-td>
                        <q-td key="notas" :props="props">{{ props.row.notas }}</q-td>
                        <q-td auto-width key="contrato">
                          <q-btn v-if="props.row.archivo.base64 != ''" size="md" color="red" rounded flat dense
                            @click="verArchivo(props.row)" icon="mdi-pdf-box">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                              content-style="font-size: 14px">Ver
                              Documento</q-tooltip>
                          </q-btn>
                        </q-td>
                      </q-tr>
                    </template>
                  </q-table>
                </div>
              </div>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>

    <!-- FINALIZAR VENTA -->
    <q-dialog v-model="dialogFinalizar" persistent>
      <q-card style="width: 600px; max-width: 80vw;">
        <q-card-section>
          <q-toolbar>
            <q-toolbar-title>
              Finalizar Venta - {{ this.venta }}
            </q-toolbar-title>
            <q-btn dense round color="green" icon="mdi-content-save" class="q-mr-sm" @click="PutFinalizar()">
              <q-tooltip>
                Guardar
              </q-tooltip>
            </q-btn>
            <q-btn round color="red" icon="mdi-close" dense v-close-popup>
              <q-tooltip>
                Cerrar
              </q-tooltip>
            </q-btn>
          </q-toolbar>
          <q-separator class="full-width" color="primary" inset size="4px" />
        </q-card-section>
        <q-card-section>
          <div class="row	q-col-gutter-sm">
            <div class="col-12">
              <q-file label="Contrato" v-model="file2" dense @input="upload2" filled accept=".pdf">
                <template v-slot:prepend>
                  <q-icon name="attach_file" />
                </template>
              </q-file>
            </div>
            <div class="col-12 col-md-12">
              <q-field dense filled label="Total" v-model="total">
                <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                  <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue" v-bind="moneyFormat"
                    v-show="floatingLabel" />
                </template>
              </q-field>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>

    <div class="row	flex-left ">
      <div class="col-12 col-md-2">
        <q-input v-model="fehaIMasked" filled label="Fecha Inicial" name="event" outlined dense>
          <template v-slot:before>
            <q-icon name="event" color="primary" />
          </template>
          <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
            <q-date v-model="fechaI">
              <div class="row items-center justify-end">
                <q-btn v-close-popup label="Ok" color="primary" flat />
              </div>
            </q-date>
          </q-popup-proxy>
        </q-input>
      </div>
      <div class="col-12 col-md-2">
        <q-input v-model="fehaFMasked" filled label="Fecha Final" name="event" outlined dense>
          <template v-slot:before>
            <q-icon name="event" color="primary" />
          </template>
          <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
            <q-date v-model="fechaF">
              <div class="row items-center justify-end">
                <q-btn v-close-popup label="Ok" color="primary" flat />
              </div>
            </q-date>
          </q-popup-proxy>
          <template v-slot:after>
            <q-btn round color="primary" icon="mdi-magnify" @click="consultar()">
              <q-tooltip>
                Buscar
              </q-tooltip>
            </q-btn>
          </template>
        </q-input>
      </div>
      <div class="col-12 col-md-8 text-right ">
        <q-btn class="q-mr-sm" round color="green" icon="mdi-microsoft-excel" @click="generarVentanaReporte()">
          <q-tooltip>
            Excel
          </q-tooltip>
        </q-btn>
        <q-btn v-if="token.group == 'admin'" color="primary" icon="mdi-plus" label="Agregar venta"
          @click="nuevoRegistro" />
      </div>
    </div>
    <div class="q-mt-md">
      <q-table class="shadow-0" title="Ventas" :data="items" :columns="columns" row-key="_id" :filter="filter"
        :loading="loading" :pagination="pagination">
        <template v-slot:loading>
          <q-inner-loading showing color="primary" />
        </template>
        <template v-slot:top-right>
          <q-select class="q-mr-sm" style="width: 200px;" filled dense v-model="filtroSelect" :options="filtros"
            option-label="label" label="Filtro por estatus" @input="filtrarEstatus()" />

          <!-- <q-select class="q-mr-sm" style="width: 200px;" filled dense v-model="filtroSelectRes" :options="filtrosRes"
            option-label="label" label="Filtro por responsable" @input="filtrarResponsable()" />

          <q-select class="q-mr-sm" style="width: 200px;" filled dense v-model="filtroSelectPro" :options="filtrosPro"
            option-label="label" label="Filtro por promotor" @input="filtrarPromotor()" /> -->

          <q-input style="width: 400px;" filled borderless dense debounce="300" v-model="filter" placeholder="Buscar">
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
        </template>
        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td v-if="token.group == 'admin'" auto-width key="acciones">
              <q-btn v-if="props.row.estatus != 'Finalizado'" size="md" color="red" rounded flat dense
                @click="confirm(props.row)" icon="mdi-delete">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                  content-style="font-size: 14px">Eliminar</q-tooltip>
              </q-btn>
              <q-btn v-if="props.row.estatus == 'Nuevo'" size="md" color="primary" rounded flat dense
                @click="editar(props.row)" icon="mdi-pencil">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                  content-style="font-size: 14px">Editar</q-tooltip>
              </q-btn>
              <q-btn v-if="props.row.estatus == 'Nuevo' || props.row.estatus == 'Proceso'" size="md" color="red" rounded
                flat dense @click="cancelar(props.row, 'Cancelado')" icon="mdi-cancel">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                  content-style="font-size: 14px">Cancelar</q-tooltip>
              </q-btn>
              <q-btn v-if="props.row.estatus != 'Cancelado'" size="md" color="purple" rounded flat dense
                @click="updateHistorial(props.row)" icon="mdi-clipboard-text-clock">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                  content-style="font-size: 14px">Historial</q-tooltip>
              </q-btn>
              <q-btn v-if="props.row.estatus == 'Proceso'" size="md" color="green" rounded flat dense
                @click="finalizar(props.row)" icon="mdi-check-circle">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                  content-style="font-size: 14px">Finalizar</q-tooltip>
              </q-btn>
            </q-td>
            <q-td v-if="token.group == 'usuario'" auto-width key="acciones">
              <q-btn v-if="props.row.estatus != 'Cancelado'" size="md" color="purple" rounded flat dense
                @click="updateHistorial(props.row)" icon="mdi-clipboard-text-clock">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                  content-style="font-size: 14px">Historial</q-tooltip>
              </q-btn>
            </q-td>
            <q-td key="idVenta" :props="props">
              <q-chip :class="props.row.estatus">
                {{ props.row.idVenta }}
                <q-tooltip>
                  {{ props.row.estatus }}
                </q-tooltip>
              </q-chip>
            </q-td>
            <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
            <q-td key="promotor" :props="props">{{ props.row.promotor }}</q-td>
            <q-td key="responsable" :props="props">{{ props.row.responsable }}</q-td>
            <q-td key="cliente" :props="props">{{ props.row.cliente.cliente }}</q-td>
            <q-td key="fecha" :props="props">{{ FormatDate(props.row.fecha) }}</q-td>
            <q-td key="presupuesto" :props="props">{{ formatCurrency(props.row.presupuesto) }}</q-td>
            <q-td key="total" :props="props">{{ formatCurrency(props.row.total) }}</q-td>
            <q-td auto-width key="contrato">
              <q-btn v-if="props.row.contrato.base64 != ''" size="md" color="red" rounded flat dense
                @click="verContrato(props.row)" icon="mdi-pdf-box">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px">Ver
                  Contrato</q-tooltip>
              </q-btn>
            </q-td>
          </q-tr>
        </template>
        <template v-slot:bottom-row>
          <q-tr>
            <q-td colspan="100%">
              <div class="row q-pa-sm q-col-gutter-sm flex-center ">
                <div class="col-12 col-md-8">
                  <q-chip color="negative" text-color="white" icon="mdi-cancel">
                    Cancelado
                  </q-chip>
                  <q-chip color="blue" text-color="white" icon="mdi-new-box">
                    Nuevo
                  </q-chip>
                  <q-chip color="orange" text-color="white" icon="mdi-update">
                    En Proceso
                  </q-chip>
                  <q-chip color="green" text-color="white" icon="mdi-check-circle">
                    Finalizado
                  </q-chip>
                </div>

                <div class="col-12 col-md-2">
                  <q-field readonly dense filled label="Total Ventas" v-model="totalVentas">
                    <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                      <Money readonly :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                        v-bind="moneyFormat" v-show="floatingLabel" />
                    </template>
                  </q-field>
                </div>

                <div class="col-12 col-md-2">
                  <q-field dense filled label="Meta Ventas" v-model="metaVentas">
                    <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                      <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                        v-bind="moneyFormat" v-show="floatingLabel" />
                    </template>
                  </q-field>
                </div>
                <div class="col-12 col-md-12">
                  <q-linear-progress size="30px" :value="progress2" :color="colorBarra" class="q-mt-sm">
                    <div class="absolute-full flex flex-center">
                      <q-badge color="white" :text-color="colorBarra" :label="progressLabel2" />
                    </div>
                  </q-linear-progress>
                </div>
              </div>
            </q-td>

          </q-tr>
        </template>
        <!-- <template v-slot:bottom>
          <q-linear-progress size="30px" :value=".75" color="green" class="q-mt-sm">
            <div class="absolute-full flex flex-center">
              <q-badge color="white" text-color="green" label="75 %" />
            </div>
          </q-linear-progress>
        </template> -->
      </q-table>
    </div>

  </q-page>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import axios from "axios";
import moment from 'moment';
import Venta from '../components/Venta/Venta.vue'
import print from 'print-js'
import { QSpinnerCube } from 'quasar'
import { Money } from 'v-money'
import * as XLSX from 'xlsx';

export default {
  name: 'Home',
  components: {
    Money,
    Venta
  },
  data() {
    return {
      filtroSelectReporte: 'Todos',
      filtroPR: '',
      listaPR: [],
      progress2: 0.3,
      colorBarra: 'red',
      metaVentas: 0,

      filtroSelect: { label: 'Todos', value: '' },
      filtroSelectRes: 'Todos',
      filtroSelectPro: 'Todos',
      filtrosRes: ['Todos'],
      filtrosPro: ['Todos'],
      datosOriginales: [],
      filtros: [
        { label: 'Todos', value: '' },
        { label: 'Nuevo', value: 'Nuevo' },
        { label: 'En Proceso', value: 'En Proceso' },
        { label: 'Finalizado', value: 'Finalizado' },
        { label: 'Cancelado', value: 'Cancelado' },
      ],

      moneyFormat: {
        decimal: '.',
        thousands: ',',
        precision: 2,
        masked: false
      },
      filter: '',
      loading: false,
      columns: [
        { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', },
        { name: 'idVenta', align: 'center', label: 'ID Venta', field: 'idVenta', },
        { name: 'descripcion', align: 'center', label: 'Descripción', field: 'descripcion' },
        { name: 'promotor', align: 'center', label: 'Promotor', field: 'promotor', sortable: true },
        { name: 'responsable', align: 'center', label: 'Responsable', field: 'responsable', sortable: true },
        { name: 'cliente', align: 'center', label: 'Cliente', field: 'cliente', sortable: true },
        { name: 'fecha', align: 'center', label: 'Fecha', field: 'fecha', sortable: true },
        { name: 'presupuesto', align: 'center', label: 'Presupuesto', field: 'presupuesto', sortable: true, classes: 'text-right text-dark bg-grey-2 ellipsis', headerClasses: 'text-white bg-primary text-center', },
        { name: 'total', align: 'center', label: 'Total', field: 'total', classes: 'text-right  text-dark  bg-grey-2 ellipsis', headerClasses: 'bg-primary text-white', },
        { name: 'contrato', align: 'center', label: 'Contrato', field: 'contrato', },
      ],
      data: [],
      dialogNuevo: false,
      pagination: {
        sortBy: 'fecha',
        descending: true,
        page: 1,
        rowsPerPage: 10
        // rowsNumber: xx if getting data from a server
      },
      dialogConfirm: false,
      venta: '',

      fechaI: new Date(),
      fechaF: new Date(),

      dialogHistorial: false,

      dialogConfirmCancelacion: false,
      estatus: '',

      historial: {
        descripcion: '',
        notas: '',
        archivo: {
          base64: '',
          nombreArchivo: '',
          tipoArchivo: ''
        },
        fecha: new Date()
      },
      file: null,
      file2: null,

      columnsHistorial: [
        { name: 'descripcion', align: 'center', label: 'Descripción', field: 'descripcion', },
        { name: 'fecha', align: 'center', label: 'Fecha', field: 'fecha', },
        { name: 'notas', align: 'center', label: 'Notas', field: 'notas', },
        { name: 'archivo', align: 'center', label: 'Archivo', field: 'archivo', }
      ],
      dialogFinalizar: false,
      contrato: {
        base64: '',
        nombreArchivo: '',
        tipoArchivo: ''
      },
      total: 0,

      prompt: false,
      itempsTipoReport: ['Todos', 'Promotor', 'Responsable']
    }
  },

  watch: {
    isDarkMode(val) {
      this.$q.dark.set(val);
    }
  },
  computed: {
    token() {
      return this.$store.state.usuario;
    },
    fehaIMasked() {
      moment.locale('es-mx');
      return this.fechaI ? moment.utc(this.fechaI).format('DD/MMMM/yyyy') : ''
    },
    fehaFMasked() {
      moment.locale('es-mx');
      return this.fechaF ? moment.utc(this.fechaF).format('DD/MMMM/yyyy') : ''
    },
    fehaMovimiento() {
      moment.locale('es-mx');
      return this.historial.fecha ? moment.utc(this.historial.fecha).format('DD/MMMM/yyyy') : ''
    },
    items() {
      return this.$store.state.listaVentasStore
    },
    item() {
      return this.$store.state.ventaStore
    },
    progressLabel2() {
      if (this.totalVentas !== 0) {
        const porcentaje = (this.totalVentas / this.metaVentas) * 100;
        this.progress2 = porcentaje / 100

        if (porcentaje > 0 && porcentaje <= 20) {
          this.colorBarra = 'red'
        }
        else if (porcentaje > 20 && porcentaje <= 40) {
          this.colorBarra = 'deep-orange'
        }
        else if (porcentaje > 40 && porcentaje <= 60) {
          this.colorBarra = 'amber'
        }
        else if (porcentaje > 60 && porcentaje <= 80) {
          this.colorBarra = 'lime'
        }
        else if (porcentaje > 80) {
          this.colorBarra = 'green'
        }
        return porcentaje.toFixed(2) + '%'
      } else {
        this.progress2 = 0;
        return '0 %';
      }
    },
    totalVentas() {
      let suma = this.items.reduce((acumulador, objeto) => acumulador + objeto.total, 0);
      let resultado = suma;
      return resultado;
    }
  },
  created() {
    this.GetClientes();
  },
  methods: {
    async GetClientes() {
      try {
        let response = await axios.get("ControlVentas/GetClientes/ventas");
        let catalogo = response.data;
        this.$store.state.listaClientesStore = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async consultar() {
      console.log(this.fechaI, this.fechaF);
      this.loading = true
      let fI = moment(this.fechaI).format('YYYY-MM-DD')
      let fF = moment(this.fechaF).format('YYYY-MM-DD')
      try {
        let response = await axios.get("ControlVentas/GetVentas/" + fI + '/' + fF);
        console.log(response)
        this.$store.state.listaVentasStore = response.data;
        this.datosOriginales = response.data;

        // Crear conjuntos para los promotores y responsables
        let promotoresSet = new Set();
        let responsablesSet = new Set();

        // Iterar sobre los datos y agregar los promotores y responsables a los conjuntos
        for (let a of response.data) {
          promotoresSet.add(a.promotor);
          responsablesSet.add(a.responsable);
        }

        // Convertir los conjuntos en listas si es necesario
        this.filtrosPro = Array.from(promotoresSet);
        this.filtrosRes = Array.from(responsablesSet);

        this.filtrosPro.unshift('Todos');
        this.filtrosRes.unshift('Todos');

        this.loading = false
      } catch (error) {
        console.log(error);
        this.loading = false
      }
    },

    // ELIMINAR VENTA
    async PutEstadoVenta() {
      this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Eliminando venta. Espere...', messageColor: 'white' })
      let venta = this.$store.state.ventaStore

      try {
        let response = await axios.put('ControlVentas/PutEstadoVentas/' + venta._id)
        console.log(response)

        let indice = this.items.indexOf(venta)
        this.items.splice(indice, 1)

        this.dialogConfirm = false
        this.$q.loading.hide()
        this.$q.notify({ type: 'positive', message: `La venta se a eliminado exitosamente.` })
      } catch (error) {
        console.log(error)
        this.$q.loading.hide()
        this.$q.notify({ type: 'negative', message: 'Error, intentelo mas tarde.', color: 'red' })
      }
    },

    // CANCELAR VENTA
    async putEstatus() {
      this.$store.state.ventaStore.estatus = this.estatus

      this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Actualizando venta. Espere...', messageColor: 'white' })
      try {
        let response = await axios.put('ControlVentas/PutVenta/', this.item)
        console.log(response)

        let indice = this.$store.state.listaVentasStore.findIndex(x => x._id === this.item._id);
        Object.assign(this.$store.state.listaVentasStore[indice], this.item)

        this.$q.loading.hide()
        this.$q.notify({ type: 'positive', message: `La venta ha sido actualizado exitosamente.` })
        this.dialogConfirmCancelacion = false

      } catch (error) {
        console.log(error)
        this.$q.loading.hide()
        this.$q.notify({ type: 'negative', message: 'Error al actualizar, verifique su información e intentelo de nuevo.', color: 'red' })
      }
    },

    // ACTUALIZAR HISTORIAL
    async actualizar() {
      this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Actualizando venta. Espere...', messageColor: 'white' })
      if (this.item.estatus != 'Finalizado') {
        this.item.estatus = 'Proceso'
      }

      try {
        let response = await axios.put('ControlVentas/PutVenta/', this.item)
        console.log(response)

        let indice = this.$store.state.listaVentasStore.findIndex(x => x._id === this.item._id);
        Object.assign(this.$store.state.listaVentasStore[indice], this.item)

        this.$q.loading.hide()
        this.$q.notify({ type: 'positive', message: `La venta ha sido actualizado exitosamente.` })
        this.dialogHistorial = false;
      } catch (error) {
        console.log(error)
        this.$q.loading.hide()
        this.$q.notify({ type: 'negative', message: 'Error al actualizar, verifique su información e intentelo de nuevo.', color: 'red' })
      }
    },

    async PutFinalizar() {
      this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Actualizando venta. Espere...', messageColor: 'white' })
      this.item.estatus = 'Finalizado'
      this.item.contrato = this.contrato
      this.item.total = this.total
      try {
        let response = await axios.put('ControlVentas/PutVenta/', this.item)
        console.log(response)

        let indice = this.$store.state.listaVentasStore.findIndex(x => x._id === this.item._id);
        Object.assign(this.$store.state.listaVentasStore[indice], this.item)

        this.$q.loading.hide()
        this.$q.notify({ type: 'positive', message: `La venta ha sido actualizado exitosamente.` })
        this.dialogFinalizar = false;
      } catch (error) {
        console.log(error)
        this.$q.loading.hide()
        this.$q.notify({ type: 'negative', message: 'Error al actualizar, verifique su información e intentelo de nuevo.', color: 'red' })
      }
    },
    cancelar(item, estatus) {
      this.$store.state.ventaStore = item
      this.estatus = estatus
      this.dialogConfirmCancelacion = true
    },

    editar(data) {
      this.$store.state.ventaStore = { ...data }
      this.dialogNuevo = true
    },

    formatCurrency(value) {
      return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    },

    FormatDate(value) {
      return moment(value).format('YYYY-MM-DD');
    },

    verContrato(item) {
      let documento = item.contrato.base64
      print({
        printable: documento.split(",")[1],
        type: 'pdf',
        base64: true,
      })
    },

    verArchivo(item) {
      let documento = item.archivo.base64
      print({
        printable: documento.split(",")[1],
        type: 'pdf',
        base64: true,
      })
    },

    nuevoRegistro() {
      let objeto = {
        _id: '',
        idVenta: '',
        descripcion: '',
        promotor: '',
        responsable: '',
        presupuesto: 0,
        total: 0,
        // metasVentas: 0,
        cliente: null,
        fecha: new Date(),
        contrato: {
          base64: '',
          nombreArchivo: '',
          tipoArchivo: ''
        },
        estatus: 'Nuevo',
        fechaCreacion: null,
        usuarioCreador: '',
        historial: [],
      }
      this.$store.state.ventaStore = objeto
      this.dialogNuevo = true
    },

    confirm(item) {
      this.venta = item.idVenta

      this.$store.state.ventaStore = item
      this.dialogConfirm = true
    },

    updateHistorial(item) {
      this.venta = item.idVenta
      this.$store.state.ventaStore = item
      this.dialogHistorial = true
    },

    async upload(event) {
      const file = event;
      try {
        const result = await this.convertBase64(file);

        this.historial.archivo.base64 = result;
        this.historial.archivo.nombreArchivo = file.name;
        this.historial.archivo.tipoArchivo = file.type;
      } catch (error) {
        console.log('error')
        console.error(error);
        return;
      }
    },

    async upload2(event) {
      const file = event;
      try {
        const result = await this.convertBase64(file);

        this.contrato.base64 = result;
        this.contrato.nombreArchivo = file.name;
        this.contrato.tipoArchivo = file.type;
      } catch (error) {
        console.log('error')
        console.error(error);
        return;
      }
    },

    convertBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = () => reject(reader.error);
      });
    },

    agregar() {
      this.historial.fecha = moment(this.historial.fecha).format('YYYY/MM/DD')

      if (this.historial.descripcion == '') {
        this.$q.notify({ type: 'warning', position: 'top-right', message: 'Agrege una descripción' })
        return;
      }

      this.$store.state.ventaStore.historial.push(this.historial);

      this.historial = {
        fecha: new Date(),
        descripcion: '',
        notas: '',
        archivo: {
          nombreArchivo: '',
          base64: '',
          tipoArchivo: ''
        }
      }
    },

    finalizar(item) {
      this.$store.state.ventaStore = item
      this.dialogFinalizar = true
      this.venta = item.idVenta
      this.contrato = {
        base64: '',
        nombreArchivo: '',
        tipoArchivo: ''
      }
    },

    async ExportarExcel() {
      let lista = []
      if (this.filtroSelectReporte == 'Promotor') {
        lista = this.items.filter(x => x.promotor == this.filtroPR)
      } else if (this.filtroSelectReporte == 'Responsable') {
        lista = this.items.filter(x => x.responsable == this.filtroPR)
      } else {
        lista = this.items
      }
      if (this.filtroSelectReporte != 'Todos') {
        if (this.filtroPR == "" || this.filtroPR == null) {
          this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el promotor o responsable' })
          return;
        }
      }
      const hojaCalculo = XLSX.utils.json_to_sheet(lista);
      const libroTrabajo = XLSX.utils.book_new();
      let fI = moment(this.fechaI).format('YYYY-MM-DD')
      let fF = moment(this.fechaF).format('YYYY-MM-DD')
      // Añadir la hoja de cálculo al libro de trabajo
      XLSX.utils.book_append_sheet(libroTrabajo, hojaCalculo, 'Hoja1');

      // Guardar el libro como un archivo XLSX
      const nombreArchivo = 'CONTROL DE VENTAS  DEL ' + fI + ' AL ' + fF

      XLSX.writeFile(libroTrabajo, nombreArchivo + '.xlsx');
      this.prompt = false
    },
    filtrarEstatus() {
      // Hacer una copia de la lista original
      this.$store.state.listaVentasStore = this.datosOriginales

      // Aplicar el filtro a la copia
      if (this.filtroSelect.value == '') {
        this.$store.state.listaVentasStore = this.datosOriginales
      } else {
        this.$store.state.listaVentasStore = this.items.filter(x => x.estatus === this.filtroSelect.value);
      }
    },

    filtrarPromotor() {
      // Hacer una copia de la lista original
      this.$store.state.listaVentasStore = this.datosOriginales

      // Aplicar el filtro a la copia
      if (this.filtroSelectPro == 'Todos') {
        this.$store.state.listaVentasStore = this.datosOriginales
      } else {
        this.$store.state.listaVentasStore = this.items.filter(x => x.promotor === this.filtroSelectPro);
      }
    },
    filtrarResponsable() {
      // Hacer una copia de la lista original
      this.$store.state.listaVentasStore = this.datosOriginales

      // Aplicar el filtro a la copia
      if (this.filtroSelectRes == 'Todos') {
        this.$store.state.listaVentasStore = this.datosOriginales
      } else {
        this.$store.state.listaVentasStore = this.items.filter(x => x.responsable === this.filtroSelectRes);
      }
    },
    generarVentanaReporte() {
      this.filtroSelectReporte = 'Todos'
      this.filtrosPro = []
      this.filtrosRes = []

      let promotoresSet = new Set();
      let responsablesSet = new Set();

      // Iterar sobre los datos y agregar los promotores y responsables a los conjuntos
      for (let a of this.items) {
        promotoresSet.add(a.promotor);
        responsablesSet.add(a.responsable);
      }

      // Convertir los conjuntos en listas si es necesario
      this.filtrosPro = Array.from(promotoresSet);
      this.filtrosRes = Array.from(responsablesSet);



      this.prompt = true
    },
    ChangeA() {
      console.log(this.filtroSelectReporte)
      if (this.filtroSelectReporte == 'Promotor') {
        this.listaPR = this.filtrosPro
      } else if (this.filtroSelectReporte == 'Responsable') {
        this.listaPR = this.filtrosRes
      } else {
        this.listaPR = []
      }
    }
  }
}
</script>
<style>
.Nuevo {
  background: #2196F3 !important;
  color: #fff !important;
}

.Cancelado {
  background: #C10015 !important;
  color: #fff !important;
}

.Finalizado {
  background: #4CAF50 !important;
  color: #fff !important;
}

.Proceso {
  background: #FF9800 !important;
  color: #fff !important;
}
</style>
