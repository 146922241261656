<template>
    <q-page class=" q-pa-lg ">
        <q-dialog v-model="dialogNuevo" persistent transition-show="scale" transition-hide="scale">
            <Usuarios @cierraVentana="dialogNuevo = false"></Usuarios>
        </q-dialog>

        <div class="row	flex-left ">
            <div class="col-12  text-right ">
                <q-btn round color="primary" class="q-mr-sm" icon="mdi-update" @click="GetUsuarios()" />
                <q-btn round color="primary" icon="mdi-plus" @click="nuevoUsuario()" />
            </div>
        </div>

        <div class="q-mt-md">
            <q-table title="Usuarios" class="shadow-0" :data="items" :columns="columns" row-key="nombre" :filter="filter"
                :loading="loading" :pagination="pagination">
                <template v-slot:loading>
                    <q-inner-loading showing color="primary" />
                </template>
                <template v-slot:top-right>
                    <q-input filled borderless dense debounce="300" v-model="filter" placeholder="Buscar">
                        <template v-slot:append>
                            <q-icon name="search" />
                        </template>
                    </q-input>
                </template>
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td auto-width key="acciones">

                            <q-btn size="md" color="primary" rounded flat dense @click="editarUsuario(props.row)"
                                icon="mdi-pencil">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Editar</q-tooltip>
                            </q-btn>
                        </q-td>
                        <q-td key="username" :props="props">{{ props.row.username }}</q-td>
                        <q-td key="nombre" :props="props">{{ props.row.nombre }}</q-td>
                        <q-td key="apellido" :props="props">{{ props.row.apellido }}</q-td>
                        <q-td key="fechaNacimiento" :props="props">{{ props.row.fechaNacimiento }}</q-td>
                        <q-td key="genero" :props="props">{{ props.row.genero }}</q-td>
                        <q-td key="telefono" :props="props">{{ props.row.telefono }}</q-td>
                        <q-td key="email" :props="props">{{ props.row.email }}</q-td>
                        <q-td key="group" :props="props">{{ props.row.group }}</q-td>
                    </q-tr>
                </template>
            </q-table>
        </div>
    </q-page>
</template>
<script>

import moment from 'moment';
import { QSpinnerCube } from 'quasar'
import axios from 'axios'
import Usuarios from './Usuarios.vue'
export default {
    components: { Usuarios },
    data() {
        return {
            filter: '',
            loading: false,
            columns: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', },
                { name: 'username', align: 'center', label: 'Nombre de usuario', field: 'username', sortable: true },
                { name: 'nombre', align: 'center', label: 'Nombre', field: 'nombre', sortable: true },
                { name: 'apellido', align: 'center', label: 'Apellidos', field: 'apellido', sortable: true },
                { name: 'fechaNacimiento', align: 'center', label: 'Fecha de nacimiento', field: 'fechaNacimiento' },
                { name: 'genero', align: 'center', label: 'Genero', field: 'genero' },
                { name: 'telefono', align: 'center', label: 'Teléfono', field: 'telefono' },
                { name: 'email', align: 'center', label: 'Email', field: 'email', sortable: true },
                { name: 'group', align: 'center', label: 'Rol', field: 'group' },
            ],
            data: [],
            dialogNuevo: false,
            pagination: {
                sortBy: 'nombre',
                descending: false,
                page: 1,
                rowsPerPage: 10
                // rowsNumber: xx if getting data from a server
            },
            dialogConfirm: false
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        items() {
            return this.$store.state.listaUsuariosStore
        }
    },
    created() {
        this.GetUsuarios();
    },
    methods: {
        nuevoUsuario() {
            let empleado = {
                _id: '',
                username: '',
                nombre: '',
                apellido: '',
                password: '',
                email: '',
                fechaNacimiento: new Date(),
                genero: '',
                status: 'active',
                telefono: '',
                group: '',
                app: 'ventas'
            }
            this.$store.state.usuarioStore = empleado
            this.dialogNuevo = true
        },
        async GetUsuarios() {
            this.loading = true
            try {
                let response = await axios.get("Login/GetEmpleados/dev_ventas/ventas");
                let catalogo = response.data;
                this.$store.state.listaUsuariosStore = catalogo;
                this.loading = false

            } catch (error) {
                this.loading = false
                console.log(error);
            }
        },

        editarUsuario(data) {
            console.log(data)
            data.password = ''
            this.$store.state.usuarioStore = { ...data }

            this.dialogNuevo = true
        },

    }
}
</script>
    
<style></style>
<style lang="sass">
    </style>
    