import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import store from '../store/index'
import ListaUsuarios from '../components/Usuarios/ListaUsuarios.vue'
import ListaVentas from '../components/Venta/Venta.vue'
import ListaClientes from '../components/Clientes/ListaClientes.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', component: Home, name: 'Home', meta: { Administrador: true } },
  { path: '/ListaUsuarios', component: ListaUsuarios, name: 'ListaUsuarios', meta: { Administrador: true } },
  { path: '/ListaVentas', component: ListaVentas, name: 'ListaVentas', meta: { Administrador: true } },
  { path: '/ListaClientes', component: ListaClientes, name: 'ListaClientes', meta: { Administrador: true } },
  { path: '/Login', name: 'Login', component: Login, meta: { libre: true } },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.libre)) {
    next()
  } else if (store.state.usuario && store.state.usuario.group == 'admin') {
    if (to.matched.some(record => record.meta.Administrador)) {
      next()
    }
  } else if (store.state.usuario && store.state.usuario.group == 'usuario') {
    if (to.matched.some(record => record.meta.Administrador)) {
      next()
    }
  }
  else {
    next({
      name: 'Login'
    })
  }
})
export default router
